import QRCode from 'qrcode'

export function generateQRCode (url) {
  try {
    return QRCode.toDataURL(url)
  } catch (err) {
    console.error(err)
    return null
  }
}

export function getStoreBearer () {
  const auth = window.localStorage.getItem('sellsuki.store.bearer')
  // auth = JSON.parse(auth)
  return auth
}

export function getUser () {
  let auth = window.localStorage.getItem('sellsuki.user')
  auth = JSON.parse(auth)
  const result = {
    first_name: auth.user.first_name,
    id: auth.user.id,
    last_name: auth.user.last_name
  }
  return result
}

export function getUserData () {
  let auth = window.localStorage.getItem('sellsuki.user')
  auth = JSON.parse(auth)
  return auth.user
}

export function setPrintItem (data) {
  window.localStorage.setItem('print_item', JSON.stringify(data))
}

export function getPrintItem () {
  const printItem = window.localStorage.getItem('print_item')
  return JSON.parse(printItem)
}

export function getEmail () {
  let auth = window.localStorage.getItem('sellsuki.user')
  auth = JSON.parse(auth)
  return auth.user.email
}

export function setPage (page) {
  window.localStorage.setItem('page', page)
}

export function getPage () {
  return window.localStorage.getItem('page')
}
